<template>
  <div class="ad-consume padding-20 box-shadow-light-grey" v-loading="loading">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.queryDate"
          style="width: 260px"
          type="year"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy"
          value-format="yyyy"
          :picker-options="disableTodayOptions"
        >
        </el-date-picker>
        <select-platform-channel :params="params" data-type="obj" />
        <select-dept-user :params="params" />
        <!-- <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          clearable
          placeholder="选择公众号"
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
          </el-option>
        </el-select> -->
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="params.labelTypes"
          collapse-tags
          multiple
          clearable
          placeholder="渠道"
          style="width: 170px"
        >
          <el-option
            v-for="(item, index) in labelListShow"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handlePageChange(page)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>
    <div class="data-card-list flex-row-start-start-wrap">
      <div
        class="statistics-card"
        v-for="(item, index) in totalData"
        :key="index"
      >
        <div class="data-label">{{ item.label }}</div>
        <div class="statistic-num-text">
          <span class="statistic-num">
            {{ item.value }}
            {{ item.label == "回本" ? "%" : "" }}
          </span>
        </div>
      </div>
      <div class="statistics-card last-three-days">
        <div class="data-label">近三日日充</div>
        <div class="three-days-data flex-row-between-center">
          <div v-for="(item, index) in lastThreeData" :key="index">
            <p>
              {{ `${item.label}:` }}
              <span class="light-color">{{ item.target[0].value }}</span>
            </p>
            <p>
              {{ `${item.target[1].label}:` }}
              <span class="light-color">{{ item.target[1].value }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :data="dataList"
      style="width: 100%"
      :height="lastParams ? '620px' : '650px'"
      empty-text="暂无数据"
      row-key="id"
      lazy
      :row-class-name="tableRowClassName"
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @cell-click="handleRowClick"
    >
      <el-table-column width="100" label="月份">
        <template slot-scope="scope">
          <span v-if="scope.row.month">{{
            scope.row.month | formatMonth
          }}</span>
          <div v-else class="data-detail" @click="handleGoIntervalUser(scope.row)">
            {{ scope.row.startWeek + "~" + scope.row.endWeek }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cashCostAmt" label="消耗"> </el-table-column>
      <el-table-column prop="newRechargeAmt" label="充值"> </el-table-column>
      <el-table-column prop="profitAmount" label="利润" width="150"></el-table-column>
      <el-table-column label="回本率">
        <template slot-scope="scope">
          <span>{{ `${scope.row.returnRate}%` }}</span>
        </template>
      </el-table-column>
      <el-table-column label="近三天日充" align="center">
        <el-table-column label="上前天">
          <template slot-scope="scope">
            <div>
              <p>充值:{{ scope.row.lastThirdRechargeAmt }}</p>
              <p>增长:{{ scope.row.lastThirdRechargeAmtRate }}%</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="前天">
          <template slot-scope="scope">
            <div>
              <p>充值:{{ scope.row.lastSecondRechargeAmt }}</p>
              <p>增长:{{ scope.row.lastSecondRechargeAmtRate }}%</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="昨天">
          <template slot-scope="scope">
            <div>
              <p  style="color: #f95c52">充值:{{ scope.row.lastRechargeAmt }}</p>
              <p>增长:{{ scope.row.lastRechargeAmtRate }}%</p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <!-- <el-table-column prop="avgAmt" label="近一月均值" width="150"> -->
      </el-table-column>
      <el-table-column label="走势图">
        <template slot-scope="scope">
          {{ drawEcharts(scope.row, scope.$index) }}
          <div
            :id="`yearDetailTable` + scope.$index"
            class="tiger-trend-charts"
          ></div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 普通分页 -->
    <!-- 公共抽屉 -->
    <CommonDrawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <graph-dialog
      :show="showGraphDialog"
      @close="showGraphDialog = false"
      :params="getGraphParams()"
    ></graph-dialog>
  </div>
</template>

<script>
import echarts from "echarts";
import { getBookList } from "@/api/account.js";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import SelectDeptUser from "@/components/SelectDeptUser";
import GraphDialog from "./graph-dialog.vue";
import {
  platformList,
  yesterdayOptions,
  labelList,
  disableTodayOptions,
} from "@/assets/js/options";
import { formatThousandsDots, formatTime } from "@/assets/js/utils";
import { getYearlyData, getWeekDetail } from "@/api/statics";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "month",
  props: {
    lastParams: Object,
  },
  components: {
    GraphDialog,
    SelectDeptUser,
    SelectPlatformChannel,
  },
  filters: {
    formatMonth(value) {
      const month = [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ];
      return month[value - 1];
    },
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    ...mapGetters(["officialList"]),
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
    params: {
      handler(newV) {},
    },
  },
  data() {
    return {
      params: {
        queryDate: "",
      },
      bookList: null,
      originFeild: [],
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      yesterdayOptions,
      labelList,
      showCommonDrawer: false,
      showTemplate: "intervalUser",
      nextParams: {},
      disableTodayOptions,
      platformList,
      queryDate: "",
      page: 1,
      totalPage: 0,
      pageSize: 0,
      lastThreeData: null, // 近三日数据
      showGraphDialog: false,
      curRowInfo: null, // 表格当前行的数据
    };
  },
  methods: {
    handleGoIntervalUser(row) {
      this.nextParams = {
        selectTime: [row.beginDate, row.endDate],
        ...this.params,
      };
      this.showCommonDrawer = true;
    },
    // 表格颜色添加
    tableRowClassName({ row, rowIndex }) {
      if (!row.profitAmount.includes("-")) {
        return "table-confirm";
      }
    },
    // 处理懒加载
    load(tree, treeNode, resolve) {
      getWeekDetail({
        ...this.params,
        labelTypes: this.params.labelTypes
          ? this.params.labelTypes.join(",")
          : null,
        beginDate: tree.beginDate,
        endDate: tree.endDate,
      }).then((res) => {
        let tmparr = res.map((item, index) => {
          return {
            ...item,
            id: parseInt(tree.id + "" + (index + 10)), //添加父子关联处理收起bug
          };
        });
        resolve(tmparr);
      });
    },
    // 缩略图绘制
    drawEcharts(data, index) {
      // 绘制趋势echarts
      const option = {
        color: ["#00bf8a", "ff7800"],
        tooltip: {
          padding: [3],
          trigger: "axis",
          // confine: true,
          // formatter: "{b}<br>{c}",
          formatter: (params) => {
            // params为悬浮框上的全部数据
            return (
              params[0].name +
              "<br>" +
              formatThousandsDots(params[0].value.toString())
            );
          },
          position: [20, -5],
        },
        xAxis: {
          type: "category",
          // data: data.dates,
          data: data.x,
          show: false,
        },
        yAxis: {
          show: false,
          boundaryGap: false,
          type: "value",
        },
        series: [
          {
            // data: data.amounts,
            data: data.y,
            type: "line",
          },
        ],
      };
      const chartId = `yearDetailTable${index}`;
      this.$nextTick(() => {
        const myChart = echarts.init(document.getElementById(chartId));
        myChart.setOption(option);
        myChart.resize();
      });
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 重置操作
    handleReset() {
      this.params = {};
      this.params.queryDate = formatTime(new Date(), "yyyy");
      this.params.accountChannelId = null;
      this.page = 1;
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 广告数据获取
    getData(flag) {
      // this.loading = true;
      const mappingArr = [
        {
          label: "消耗",
          prop: "cashCostAmt",
        },
        {
          label: "充值",
          prop: "newRechargeAmt",
        },
        {
          label: "回本",
          prop: "returnRate",
        },
        {
          label: "近一月均值",
          prop: "avgAmt",
        },
      ];

      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          page: this.page,
          ...this.params,
        };
      } else if (flag === "drawer") {
        tmpform = {
          ...this.lastParams,
          page: this.page,
        };
        this.params = { ...this.lastParams };
      }
      this.dataList = [];
      this.loading = true;
      getYearlyData({
        ...this.params,
        labelTypes: this.params.labelTypes
          ? this.params.labelTypes.join(",")
          : null,
        accountChannelId: this.params.currentOfficial
          ? this.params.currentOfficial.id
          : null,
      })
        .then((res) => {
          this.dataList = res.list.reverse().map((item, index) => {
            return {
              ...item,
              hasChildren: true,
              id: item.month, //添加父子关联处理张开收起
            };
          });
          this.lastThreeData = [
            {
              label: "上前天",
              target: [
                {
                  label: "充值",
                  value: res.total.lastThirdRechargeAmt,
                },
                {
                  label: "增长",
                  value: `${res.total.lastThirdRechargeAmtRate}%`,
                },
              ],
            },
            {
              label: "前天",
              target: [
                {
                  label: "充值",
                  value: res.total.lastSecondRechargeAmt,
                },
                {
                  label: "增长",
                  value: `${res.total.lastSecondRechargeAmtRate}%`,
                },
              ],
            },
            {
              label: "昨天",
              target: [
                {
                  label: "充值",
                  value: res.total.lastRechargeAmt,
                },
                {
                  label: "增长",
                  value: `${res.total.lastRechargeAmtRate}%`,
                },
              ],
            },
          ];
          // this.totalData = [];
          this.totalData = [
            {
              label: "消耗",
              value: res.total.cashCostAmt,
            },
            {
              label: "充值",
              value: res.total.newRechargeAmt,
            },
            {
              label: "利润",
              value: res.total.profitAmount,
            },
            {
              label: "回本",
              value: res.total.returnRate,
            },
          ];
          // Object.keys(res.total).forEach((key) => {
          //   const target = mappingArr.find((val) => val.prop === key);
          //   if (target) {
          //     this.totalData = [
          //       ...this.totalData,
          //       {
          //         label: target.label,
          //         prop: target.prop,
          //         value: res.total[key],
          //       },
          //     ];
          //   }
          //   // 调整次序
          //   this.totalData = [
          //     ...this.totalData.slice(1, this.totalData.length),
          //     this.totalData[0],
          //   ];
          // });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRowClick(row, column) {
      if (column.label == "走势图") {
        this.curRowInfo = row;
        this.showGraphDialog = true;
      }
    },
    getGraphParams() {
      return {
        ...this.curRowInfo,
        ...this.params,
      };
    },
  },
  created() {
    let tmpYear = new Date().getFullYear();
    this.$set(this.params, "queryDate", tmpYear + "");
    this.getBook();
    if (this.lastParams) {
      // this.handleGetFeilds("drawer");
      this.getData("drawer");
      return false;
    }
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    // if (tmpIndex !== -1) {
    //   this.params = this.$store.state.routerHistory[tmpIndex].params;
    // }
    this.getData("created");
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
/deep/.el-calendar__header {
  display: none;
}
/deep/.el-calendar__body {
  height: 690px;
  overflow: auto;
  padding: 10px;
}
// /deep/ .el-calendar-table {
//   width: 900px !important;
// }
/deep/ .el-calendar-table .el-calendar-day {
  height: 100px;
}
.detail-page /deep/.el-calendar__body {
  width: 100% !important;
}
.tiger-trend-charts {
  height: 60px;
  min-width: 100px;
}
.statistics-card {
  height: 88px;
  .statistic-num {
    display: inline-block;
    margin-top: 10px;
  }
}
.last-three-days {
  width: 400px;
  font-size: 14px;
  .three-days-data {
    // margin-top:10px;
    > div {
      > p {
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }
}
.light-color {
  color: #22c7b1;
}
/deep/ .data-detail {
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}
/deep/ .el-table__row--level-1 {
  color: #494fa3;
}
</style>
