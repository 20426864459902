<template>
    <el-dialog title='日充值线形图' :visible.sync="show" width="800px" append-to-body center :before-close="handleClose">
        <div class="graph-container flex-center-center">
            <div
                v-loading="loadingEdGraph"
                id="edDateGraph"
                :style="'width:700px;height: 300px;z-index:1;'"
            ></div>
        </div>
    </el-dialog>
</template>

<script>
import { getEdRechargeGraph } from '@/api/statics'
import echarts from 'echarts'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      edDateGraph: null,
      loadingEdGraph: false
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) this.initData()
      }
    }
  },
  methods: {
    async initData () {
      const tmpForm = this.getParams()
      this.loadingEdGraph = true
      getEdRechargeGraph(tmpForm).then(async (res) => {
        await this.initGraph()
        await this.setOptionEverydayRechargeGraph(res.x, res.y)
      }).finally(() => {
        this.loadingEdGraph = false
      })
    },
    initGraph () {
      return new Promise(resolve => {
        this.edDateGraph = echarts.init(document.getElementById('edDateGraph'))
        window.onresize = this.edDateGraph.resize
        resolve()
      })
    },
    getParams () {
      let tmpForm
      if (this.params && Object.keys(this.params).length > 0) {
        tmpForm = {
          beginDate: this.params.beginDate,
          endDate: this.params.endDate,
          labelTypes: this.params.labelTypes
            ? this.params.labelTypes.join(',')
            : '',
          comperingType: this.params.comperingType,
          returnRate: this.params.returnRate,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId || null,
          booksId: this.params.booksId,
          deptId: this.params.deptId,
          adminUserId: this.params.adminUserId
        }
        return tmpForm
      }
    },
    handleClose () {
      this.$emit('close')
    },
    setOptionEverydayRechargeGraph (x, y) {
      return new Promise((resolve) => {
        this.edDateGraph.setOption(
          {
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '5%',
              right: '5%',
              bottom: '10%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: x,
              boundaryGap: false
            },
            yAxis: {
              type: 'value',
              name: '日充值线形图'
            },
            series: [
              {
                data: y,
                type: 'line',
                smooth: true
              }
            ]
          },
          true
        )
        this.$nextTick(() => {
          resolve()
        })
      })
    }

  },
  components: {}
}
</script>
<style lang='scss' scoped>
</style>
